const StatisticIndicative = (props)=>{
    return(
        <>
        <div className='flex w-full lg:w-[385px] h-[160px] flex-row rounded-[10px] shadow-lg bg-[#FFFFFF] justify-center items-center gap-[14px]'>
            <div className="text-[57px] font-bold flex justify-center items-center text-[#238800]">
                {props.value}%
            </div>
            <div className="flex flex-col justify-end items-start">
                <h4 className="font-bold text-[22px] text-[#238800]">{props.title}</h4>
                <p className="text-[16px] !m-0">{props.desc}</p>
            </div>
        </div>
    </>
    )
}

export default StatisticIndicative;