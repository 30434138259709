export const newsSchema = (props) => {

  //console.log('newsSchema', props);

  return {
    title: 'Slide',
    addItem: '01',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['newsObj', 'customTitle', 'hideModal', 'verticalOffset'],
      },
    ],
    properties: {
      newsObj: {
        title: 'Selecione uma notícia ou imagem',
        description: "Se você selecionar uma notícia, você poderá customizar tanto o título quanto a imagem do banner. Se você selecionar uma imagem, você poderá customizar o título e deverá inserir o link de redirecionamento do banner.",
        widget: 'object_browser',
        mode: 'single',
        selectableTypes: ['News Item', 'Image'],
      },
      customTitle: {
        title: 'Usar um título customizado',
        type: 'boolean',
      },
      hideModal: {
        title: 'Esconder modal',
        type: 'boolean',
      },
      verticalOffset: {
        title: 'Posição da imagem',
        description: 'Ajustar a posição da imagem verticalmente',
        default: "top",
        choices: [
          ['top', 'cima'],
          ['center', 'meio'],
          ['bottom', 'baixo'],
        ]
      },
      link: {
        title: 'Link',
        mode: 'link',
      },
    },
    required: ['newsObj', 'hideTitle', 'verticalOffset'],
  };
};
