const NegativeIndicative = (props)=>{
    return(
        <>
            <div className='flex w-full lg:w-[279px] h-[160px] flex-col rounded-[10px] shadow-lg bg-[#FFFFFF] justify-center item-center'>
                <h2 className="flex flex-row justify-center text-[57px] text-[#B40808]">{props.value}%</h2>
                <h3 className="subtitleUtfprNumbers flex flex-row justify-center text-[22px] text-[#B40808]">{props.title}</h3>
                <p className="flex flex-row justify-center text-[16px] !m-0">{props.desc}</p>
            </div>
        </>
    )
}

export default NegativeIndicative;