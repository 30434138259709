import {useEffect, useState} from "react";

const MetricsIndicative = (props)=>{

    const [metrics, setMetrics] = useState([]);

    useEffect(()=>{
        if(props.data?.length > 0){
            let auxArr = [];
            props.data.forEach((metric, index)=>{
                if(index < 16){
                    auxArr.push(<>
                        <div className="w-full flex flex-row justify-between border-b-[2px] border-[#DFE2EB]">
                            <span className="text-[16px]">
                                {metric.year}
                            </span>
                            <span className="text-[16px]">
                                {metric.number}
                            </span>
                        </div>
                    </>)
                }
            })
            setMetrics(auxArr);
        }else{
            setMetrics("Nenhuma métrica!");
        }
    }, [props]);

    return(
        <div className='flex w-full lg:w-[378px] h-[508px] flex-col rounded-[10px] p-[32px] gap-[4px] shadow-lg bg-[#FFFFFF] item-center'>
                <div className="flex flex-col">
                    <div className="flex flex-row justify-center text-[22px] font-bold">Métricas</div>
                    <p className="subtitleUtfprNumbers flex flex-row justify-center text-[16px] !m-[0]">{props.desc}</p>
                </div>
                
                <div className="text-center">
                    {metrics}
                </div>
        </div>
    )
}

export default MetricsIndicative;